import React from "react"
import { FormattedMessage } from "react-intl"
import { Link } from "gatsby"

import Seo from '../components/seo'
import Icon from '../components/iconfontCpt'
import Layout from "../components/layout"

import '../style/download.scoped.scss'

import { allToolsMenu, allDriversMenu } from '../utils/config.js'

const Download = ({ pageContext }) => {
  const pageLang = pageContext.lang

  return (
    <Layout pageLang={pageLang} showHeader={true} showFooter={true} useTransparentTheme={true} customClass="use-translucent-theme">
      <Seo title="MogDB: Download" />
      <div className="w-banner-container">
        <div className="w-banner-box"><div className="w-banner-text"><FormattedMessage id="productDownload.pageTitle" /></div></div>
      </div>
      <div className="download-container">
        {allToolsMenu.map((item, idx) => (
          <div key={idx}>
            <div className="type-title">{item[pageLang]}</div>
            {item.menu.map((m, i) => (
              <div className="product-box" key={i}>
                <div className={`w-product-icon w-product-${m.key}-icon`}>
                  <Icon type={`icon-${m.key}`} size={20} />
                </div>
                <div className="product">
                  <div className="product-title">{m.label}</div>
                  <div className="product-desc">{m[pageLang] && (m[pageLang].desc || m[pageLang].title)}</div>
                </div>
                <a className="download-link w-download-btn" href={m[pageLang] && m[pageLang].downloadLink} target={ m.key === 'mogdbStack' ? '_blank' : '_self' }><span className="more-text"><FormattedMessage id="download.download" /></span><Icon type="icon-download" size={12}/></a>
              </div>
            ))}
          </div>
        ))}

        {allDriversMenu &&
          <div>
            <div className="type-title">{allDriversMenu[pageLang]}</div>
            {allDriversMenu.menu.map((m, i) => (
              <div className="product-box" key={i}>
                <div className={`w-product-icon w-product-driver-icon`}>
                  <Icon type="icon-driver2" size={20} />
                </div>
                <div className="product">
                  <div className="product-title drivers">{m.label}</div>
                </div>
                <a className="download-link w-download-btn" href={m[pageLang] && m[pageLang].downloadLink}><span className="more-text"><FormattedMessage id="download.download" /></span><Icon type="icon-download" size={12}/></a>
              </div>
            ))}
          </div>
        }
      </div>
    </Layout>
  )
}
export default Download